import React from "react"
import { useMediaQuery } from "@mui/material"
import { Typography } from "components/typography"
import { Link } from "gatsby"
import CreditCardProduct from "models/product-models/CreditCardProduct"
import CreditCardProvider from "models/provider-models/CreditCardProvider"
import store from "state/store"
import * as TickLines from "../tick-lines"
import { CreditCard as ProductDetails } from "components/content/product-details"
import { CreditCardOffer as Offer } from "../offer"
import CreditCardOffer from "models/offer-models/CreditCardOffer"
import * as styles from "./style.module.scss"
import ReactDOMServer from "react-dom/server"
import AppManager from "services/app-manager"
import { JsonLd } from "react-schemaorg"
import { FAQPage } from "schema-dts"

const ProviderArticle = ({ provider, rewards }: { provider: CreditCardProvider; rewards: string }) => {
  if (provider.productsByRewards[rewards] === undefined) {
    return null
  } else if (provider.productsByRewards[rewards].filter(r => r.forSale !== "GRANDFATHERED").length === 0) {
    return null
  }

  if (provider.heading === "American Express") {
    return null
  }

  const isTablet = !useMediaQuery("(min-width:1024px)")

  const allRewardPrograms = Object.values(AppManager.getInstance().getReducedCreditCardRewards())

  const directRewardPrograms = Object.keys(provider.productsByRewards).reduce((result, r) => {
    if (provider.productsByRewards[r][0].directRewardId === null && r !== "No Reward Points") {
      result[r] = provider.productsByRewards[r]
    }
    return result
  }, {})

  Object.keys(directRewardPrograms).forEach(rewardProgram => {
    directRewardPrograms[rewardProgram] = directRewardPrograms[rewardProgram].filter(p => p.forSale !== "GRANDFATHERED")
  })

  const providerRewards = Object.keys(directRewardPrograms).find(r => allRewardPrograms.some(x => x.name === r && x.rewardsCategory === "general_rewards"))
  const providerRewardsHeading = providerRewards === "Amplify" ? provider.heading + " Amplify" : providerRewards
  const otherRewards = Object.keys(directRewardPrograms).filter(r => r !== providerRewards)

  const providerRewardProgram = allRewardPrograms.find(r => r.name === providerRewards)
  if (providerRewardProgram == null) {
    return null
  }
  const indirectRewardPrograms = Object.values(providerRewardProgram.indirectPoints).filter(p => p != null)

  let categoryNum = 0
  if (providerRewardProgram.giftcardsAvailable) {
    categoryNum += 1
  }
  if (providerRewardProgram.points100Cashback) {
    categoryNum += 1
  }
  if (indirectRewardPrograms.length > 0) {
    categoryNum += 1
  }
  if (provider.heading === "Woolworths") {
    categoryNum += 1
  }

  let indirectRewardProgramsString = ""
  let lastRewardsStr = ""
  if (indirectRewardPrograms.length > 0) {
    for (let i = 0; i < Object.keys(providerRewardProgram.indirectPoints).length; i++) {
      if (Object.values(providerRewardProgram.indirectPoints)[i]) {
        const indirectStr = Object.keys(providerRewardProgram.indirectPoints)[i].substring(8)
        const indirectRewardsId = indirectStr.replace(/^\D+/g, "")
        if (indirectRewardsId) {
          const indirectRewards = allRewardPrograms.find(r => r.id == indirectRewardsId)
          indirectRewardProgramsString += indirectRewards.nameFormatted + ", "
          lastRewardsStr = indirectRewards.nameFormatted
        }
      }
    }
  }
  indirectRewardProgramsString = indirectRewardProgramsString.replace(lastRewardsStr + ", ", "and" + lastRewardsStr)

  const questions = ["How do " + providerRewardsHeading + " credit cards work?", "Which cards come with " + providerRewardsHeading + "?", "How do the " + provider.heading + " " + rewards + " credit cards work?", "Which cards come with " + rewards + "?", "Which should you get: " + providerRewardsHeading + " or " + rewards + "?"]

  let answer1 = "The " + providerRewardsHeading + " program is designed to give you flexibility in the way you choose to redeem rewards points. It allows you to accumulate rewards on your everyday purchases and doesn’t cap the number of points you can earn."
  answer1 += "You can then spend these points across a range of categories, including "
  if (provider.heading === "Woolworths") {
    answer1 += "Shopping Discount."
  } else {
    if (providerRewardProgram.giftcardsAvailable) {
      answer1 += "Gift cards"
    }
    if (providerRewardProgram.giftcardsAvailable && providerRewardProgram.points100Cashback) {
      answer1 += " and "
    }
    if (providerRewardProgram.points100Cashback) {
      answer1 += "Cashback"
    }
    answer1 += ". "
  }
  if (indirectRewardPrograms.length > 0) {
    answer1 += "You can also convert your " + providerRewardsHeading + " points to frequent flyer points with " + indirectRewardPrograms.length + " different partners, including "
    Object.keys(providerRewardProgram.indirectPoints).map(p => {
      if (providerRewardProgram.indirectPoints[p]) {
        const indirectStr = p.substring(8)
        const indirectRewardsId = indirectStr.replace(/^\D+/g, "")
        if (indirectRewardsId) {
          const indirectRewards = allRewardPrograms.find(r => r.id == indirectRewardsId)
          answer1 += indirectRewards.nameFormatted + ", "
        } else {
          answer1 += indirectStr
        }
      }
    })
  }
  answer1 = answer1.replace(/,\s*$/, ".")

  let answer2 = ""
  if (Object.keys(directRewardPrograms[providerRewards]).length === 1) {
    answer2 = "The " + directRewardPrograms[providerRewards][0].nameLong + " credit card is the only " + provider.heading + " credit card that features " + providerRewardsHeading + "."
  } else {
    answer2 += provider.heading + " offers " + Object.keys(directRewardPrograms[providerRewards]).length + " different cards that come with " + providerRewardsHeading + " . These include the entry-level " + directRewardPrograms[providerRewards][0].nameLong + " card, as well as the " + directRewardPrograms[providerRewards][1].nameLong
    if (directRewardPrograms[providerRewards][2]) {
      answer2 += " and the " + directRewardPrograms[providerRewards][2].nameLong
    }
    answer2 += ". For a higher fee, these offer the opportunity to earn more rewards points, and feature other travel-related benefits."
  }

  let answer3 = "As the name suggests when you spend money on your " + provider.heading + " " + rewards + " card, you earn " + rewards + " Points directly. " + (provider.productsByRewards[rewards].some(product => product.tier1Cap === 9999999 || product.tier2Cap === 9999999) ? "Points are uncapped and the amount you’ll receive depends on how much you spend." : "")
  answer3 += rewards + " program is extensive, giving you the opportunity to use points for domestic and international flights and upgrades. You can also redeem points for:"
  answer3 += " hotels; shopping, including gift cards and direct purchases from the Qantas Store; purchases through Qantas Wine; Qantas Travel Insurance; and hire cars and fuel through BP"

  let answer4 = ""
  if (Object.keys(directRewardPrograms[rewards]).length === 1) {
    answer4 = "The " + directRewardPrograms[rewards][0].nameLong + " credit card is the only " + provider.heading + " credit card that features " + rewards + "."
  } else {
    answer4 = provider.heading + " offers " + Object.keys(directRewardPrograms[rewards]).length + " different cards that link to " + rewards + ". These include the entry-level " + directRewardPrograms[rewards][0].nameLong + " card, as well as the " + directRewardPrograms[rewards][1].nameLong + (directRewardPrograms[rewards][2] ? " and the " + directRewardPrograms[rewards][2].nameLong : "") + ". For a higher fee, these offer the opportunity to earn more rewards points, and feature other travel-related benefits."
  }

  let answer5 = "Ultimately, whether you should choose a " + providerRewardsHeading + " or a " + provider.heading + " " + rewards + " credit card depends largely on how you intend to redeem the points."
  answer5 += "If you’re happy flying " + rewards.split(" ")[0] + " and shopping through its partners, then earning " + rewards + " points directly can be a great option."
  if (provider.heading !== "Woolworths" && indirectRewardPrograms.length > 1) {
    answer5 += " Alternatively, if you’d prefer to fly another airline, then the " + providerRewardsHeading + " card gives you the opportunity to redeem through " + indirectRewardPrograms.length + " different frequent flyer loyalty programs schemes " + (rewards === "Qantas Frequent Flyer" ? " (Qantas isn’t one of them)." : "")
  }
  answer5 += "If cost is a consideration, it’s also worth noting that the " + providerRewardsHeading + " cards tends to be slightly cheaper than the " + provider.heading + " " + rewards + " cards."
  if (provider.heading !== "Woolworths" && indirectRewardPrograms.length > 0) {
    answer5 += " But if you’re earning more points on an airline you’d choose to fly, this probably won’t matter."
  }
  answer5 += "Alternatively, if you don’t feel like you’ll get value out of rewards points, a low-cost no-frills card could save you money."

  const answers = [answer1, answer2, answer3, answer4, answer5]

  const compileSchemaOrgQa = () => {
    const mainEntity: SchemaValue<Thing | IdReference, "mainEntity"> = []
    for (let i = 0; i < questions.length; i++) {
      const answerHtml = ReactDOMServer.renderToStaticMarkup(answers[i])
      let htmlNode = null
      if (typeof window !== "undefined" && !window.document) {
        htmlNode = document.createElement("div")
        htmlNode.innerHTML = answerHtml
        htmlNode.querySelectorAll("*").forEach(function (node) {
          node.removeAttribute("style")
          node.removeAttribute("class")
        })
      }
      const entity = {
        "@type": "Question",
        name: questions[i],
        acceptedAnswer: {
          "@type": "Answer",
          text: answerHtml.replaceAll(/<\/?p>/g, ""),
        },
      }
      mainEntity.push(entity)
    }
    return (
      <JsonLd<FAQPage>
        item={{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          mainEntity: mainEntity,
        }}
      />
    )
  }

  const hasOfferLines = (offer: any) => {
    if (offer.offerHeadline) {
      return offer.offerHeadline
    } else if (offer.bonusPointsY1 || offer.bonusPointsY2 || offer.bonusPointsY3) {
      return "Bonus Points Offer"
    } else if (offer.introPurchaseRate !== null && offer.introPurchaseMonths !== null && offer.introPurchaseRate === 0 && offer.introPurchaseMonths >= 12 && offer.btRate !== null && offer.btMonths !== null && offer.btRate === 0 && offer.btMonths >= 12) {
      return "Purchase & Balance Transfer Offer"
    } else if (offer.btRate !== null && offer.btMonths !== null && offer.btRate === 0 && offer.btMonths >= 12) {
      return "Balance Transfer Offer"
    } else if (offer.introPurchaseRate !== null && offer.introPurchaseMonths !== null && offer.introPurchaseRate === 0 && offer.introPurchaseMonths >= 12) {
      return "Purchase Offer"
    } else if (offer.cashBack !== null) {
      return "Cash Back Offer"
    } else if (offer.afYear1 !== null) {
      return "Introductory Offer"
    } else {
      return null
    }
  }

  const providerRewardsOffersList: JSX.Element[] = []
  provider.productsByRewards[providerRewards].forEach((product: CreditCardProduct) => {
    let offersList: JSX.Element[] = []
    Object.keys(product.offers).forEach((offerId, offerKey) => {
      const offer = product.offers[offerId]
      if (hasOfferLines(offer)) {
        offersList.push(<Offer key={offerId.toString() + "-" + offerKey.toString()} offerJson={product.offers[offerId] as CreditCardOffer} providerName={product.providerName} cardHeading={product.cardHeading} isTablet={isTablet} />)
      }
    })
    offersList = offersList.sort((a, b) => (a.props.offerJson.active >= b.props.offerJson.active ? 1 : -1)).reverse()

    if (offersList.length > 0) {
      providerRewardsOffersList.push(
        <div>
          <Typography.P comp="medium-bold">{product.nameLong}</Typography.P>
          <div style={{ marginTop: "20px" }}>{offersList}</div>
        </div>,
      )
    }
  })

  const rewardsOffersList: JSX.Element[] = []
  provider.productsByRewards[rewards].forEach((product: CreditCardProduct) => {
    let offersList: JSX.Element[] = []
    Object.keys(product.offers).forEach((offerId, offerKey) => {
      const offer = product.offers[offerId]
      if (hasOfferLines(offer)) {
        offersList.push(<Offer key={offerId.toString() + "-" + offerKey.toString()} offerJson={product.offers[offerId] as CreditCardOffer} providerName={product.providerName} cardHeading={product.cardHeading} isTablet={isTablet} />)
      }
    })
    offersList = offersList.sort((a, b) => (a.props.offerJson.active >= b.props.offerJson.active ? 1 : -1)).reverse()

    if (offersList.length > 0) {
      rewardsOffersList.push(
        <div>
          <Typography.P comp="medium-bold">{product.nameLong}</Typography.P>
          <div style={{ marginTop: "20px" }}>{offersList}</div>
        </div>,
      )
    }
  })

  const generateBenefitsTable = (products: CreditCardProduct[]) => {
    let hasOtherBenefits = false
    const otherBenefits = []

    products.forEach(p => {
      const product = p as CreditCardProduct
      const obComponent = <TickLines.CreditCard product={product} learnMore={false} isArticle={true} />
      if (ReactDOMServer.renderToStaticMarkup(obComponent)) {
        hasOtherBenefits = true
        otherBenefits.push(<td style={{ verticalAlign: "top" }}>{obComponent}</td>)
      } else {
        otherBenefits.push(
          <td style={{ verticalAlign: "top" }}>
            <Typography.P comp={"product-detail"}>n/a</Typography.P>
          </td>,
        )
      }
    })

    if (isTablet) {
      return (
        <table>
          {products.map((product, i) => {
            return (
              <tr>
                <th>{product.nameLong}</th>
                <td>
                  <div className={styles.benefitTableDivMobile}>
                    <span>Fee</span>${product.annualFee}
                  </div>
                </td>
                <td>
                  <div className={styles.benefitTableDivMobile}>
                    <span>International transaction fee</span>
                    {parseFloat((product.fxFee * 100).toFixed(2)).toLocaleString()}%
                  </div>
                </td>
                <td>
                  <div className={styles.benefitTableDivMobile}>
                    <span>Purchase rate</span>
                    {(product.cashRate * 100).toLocaleString()}% p.a.
                  </div>
                </td>
                <td>
                  <div className={styles.benefitTableDivMobile}>
                    <span>Free Days</span>
                    up to {product.freeDays}
                  </div>
                </td>
                <td>
                  <div className={styles.benefitTableDivMobile}>
                    <span>Rewards points earned</span>
                    <ProductDetails product={product} provider={provider} hideOffer={true} isArticle={true} />
                  </div>
                </td>
                {hasOtherBenefits ? (
                  <td>
                    <div className={styles.benefitTableDivMobile}>
                      <span>Other benefits</span>
                      {otherBenefits[i]}
                    </div>
                  </td>
                ) : null}
                {products.some(p => p.minLimit != null) ? (
                  <td>
                    <div className={styles.benefitTableDivMobile}>
                      <span>Minimum credit limit</span>
                      {product.minLimit ? "$" + product.minLimit.toLocaleString() : "n/a"}
                    </div>
                  </td>
                ) : null}
              </tr>
            )
          })}
        </table>
      )
    }

    return (
      <table>
        <tr>
          <th>Card</th>
          {products.map(product => {
            return <td>{product.nameLong}</td>
          })}
        </tr>
        <tr>
          <td>Fee</td>
          {products.map(product => {
            return <td>${product.annualFee}</td>
          })}
        </tr>
        <tr>
          <td>International transaction fee</td>
          {products.map(product => {
            return <td>{parseFloat((product.fxFee * 100).toFixed(2)).toLocaleString()}%</td>
          })}
        </tr>
        <tr>
          <td>Purchase rate</td>
          {products.map(product => {
            return <td>{(product.cashRate * 100).toLocaleString()}% p.a.</td>
          })}
        </tr>
        <tr>
          <td>Free Days</td>
          {products.map(product => {
            return <td>up to {product.freeDays}</td>
          })}
        </tr>
        <tr>
          <td>Rewards points earned</td>
          {products.map(product => {
            return (
              <td>
                <ProductDetails product={product} provider={provider} hideOffer={true} isArticle={true} />
              </td>
            )
          })}
        </tr>
        {hasOtherBenefits ? (
          <tr>
            <td>Other benefits</td>
            {otherBenefits}
          </tr>
        ) : null}
        {products.some(p => p.minLimit != null) ? (
          <tr>
            <td>Minimum credit limit</td>
            {products.map(product => {
              return <td>{product.minLimit ? "$" + product.minLimit.toLocaleString() : "n/a"}</td>
            })}
          </tr>
        ) : null}
      </table>
    )
  }

  return (
    <div style={{ marginTop: "150px" }}>
      {compileSchemaOrgQa()}
      <Typography.H2>Are {providerRewardsHeading} or Qantas Frequent Flyer rewards better?</Typography.H2>

      <Typography.P>
        If you take out {"aeiouAEIOU".indexOf(provider.heading.substring(0, 1)) != -1 ? "an" : "a"} {provider.heading} loyalty points Credit Card you’ll generally have the option to link it to {otherRewards.join(", ") + " or " + providerRewards}. We look at the pros and cons of each rewards program to help you choose which is right for you.
      </Typography.P>

      {categoryNum > 0 ? (
        <div>
          <Typography.H3>{questions[0]}</Typography.H3>
          <Typography.P>The {providerRewardsHeading} program is designed to give you flexibility in the way you choose to redeem rewards points. It allows you to accumulate rewards on your everyday purchases and doesn’t cap the number of points you can earn.</Typography.P>
          <br />
          <Typography.P>
            You can then spend these points across a range of categories, including {providerRewardProgram.giftcardsAvailable ? "Gift cards" : ""}
            {providerRewardProgram.giftcardsAvailable && providerRewardProgram.points100Cashback ? " and " : " "}
            {providerRewardProgram.points100Cashback ? "Cashback" : ""}.{indirectRewardPrograms.length > 0 ? " You can also convert your " + providerRewardsHeading + " points to frequent flyer points with " + indirectRewardPrograms.length + " different partners, including " + indirectRewardProgramsString + "." : ""}
          </Typography.P>
          <br />
          <Typography.P>The following provides more information about these redemption options:</Typography.P>
          <Typography.P>
            <ul>
              {provider.heading === "Woolworths" ? (
                <li>
                  <b>Shopping Discount.</b> Money off your next shop at Woolworths
                </li>
              ) : (
                <div>
                  {providerRewardProgram.giftcardsAvailable ? (
                    <li>
                      <b>Gift cards.</b> You can convert your {providerRewardsHeading} points for gift cards through a number of retail partners. These include {providerRewardProgram.giftcardsAvailable}.
                    </li>
                  ) : null}
                  {providerRewardProgram.points100Cashback ? (
                    <li>
                      <b>Cashback.</b> {providerRewardsHeading} has a cashback scheme that offers you $5 in return for every {(providerRewardProgram.points100Cashback / 20).toLocaleString()} points you redeem.
                    </li>
                  ) : null}
                </div>
              )}
              {indirectRewardPrograms.length > 0 ? (
                <li>
                  <b>Airline partner{indirectRewardPrograms.length > 1 ? "s" : ""}. </b>
                  {indirectRewardPrograms.length > 1 ? <span>{indirectRewardPrograms.length} different frequent flyer programs partner with </span> : null}
                  {providerRewardsHeading} offering the following rates of exchange:
                  <ul>
                    {Object.keys(providerRewardProgram.indirectPoints).map(p => {
                      if (providerRewardProgram.indirectPoints[p]) {
                        const indirectStr = p.substring(8)
                        const indirectRewardsId = indirectStr.replace(/^\D+/g, "")
                        if (indirectRewardsId) {
                          const indirectRewards = allRewardPrograms.find(r => r.id == indirectRewardsId)
                          return (
                            <li>
                              <b>{indirectRewards.nameFormatted}.</b> {parseFloat((Math.ceil(providerRewardProgram.indirectPoints[p] * 100) / 100).toFixed(2))} {providerRewardsHeading} {Math.ceil(providerRewardProgram.indirectPoints[p] * 100) / 100 === 1 ? "Point" : "Points"} = 1 {indirectRewards.pointsName.endsWith("s") ? indirectRewards.pointsName.slice(0, -1) : indirectRewards.pointsName}
                            </li>
                          )
                        } else {
                          return (
                            <li>
                              <b>{indirectStr}.</b> {parseFloat((Math.ceil(providerRewardProgram.indirectPoints[p] * 100) / 100).toFixed(2))} {providerRewardsHeading} {Math.ceil(providerRewardProgram.indirectPoints[p] * 100) / 100 === 1 ? "Point" : "Points"} = 1 {indirectStr}
                            </li>
                          )
                        }
                      }
                    })}
                  </ul>
                </li>
              ) : null}
            </ul>
          </Typography.P>
        </div>
      ) : null}

      <Typography.H3>{questions[1]}</Typography.H3>
      {Object.keys(directRewardPrograms[providerRewards]).length === 1 ? (
        <Typography.P>
          The {directRewardPrograms[providerRewards][0].nameLong} credit card is the only {provider.heading} credit card that features {providerRewardsHeading}.
        </Typography.P>
      ) : (
        <div>
          <Typography.P>
            {provider.heading} offers {Object.keys(directRewardPrograms[providerRewards]).length} different cards that come with {providerRewardsHeading}. These include the entry-level {directRewardPrograms[providerRewards][0].nameLong} card, as well as the {directRewardPrograms[providerRewards][1].nameLong}
            {directRewardPrograms[providerRewards][2] && " and the " + directRewardPrograms[providerRewards][2].nameLong}. For a higher fee, these offer the opportunity to earn more rewards points, and feature other travel-related benefits.
          </Typography.P>
          <br />
          {provider.heading === "ANZ" ? (
            <Typography.P>
              The fourth {providerRewardsHeading} card is the {providerRewardsHeading} Travel Adventures. This is a ‘no international’ fee card which could potentially save you money if you’re travelling overseas or you do a lot of internet shopping using overseas-based companies.
            </Typography.P>
          ) : null}
          {provider.heading === "CommBank" ? <Typography.P>The fourth CommBank Awards card is the CommBank Ultimate Awards credit card. This is a ‘no international’ fee card which could potentially save you money if you’re travelling overseas or you do a lot of internet shopping using overseas-based companies.</Typography.P> : null}
        </div>
      )}

      <Typography.H3>Summary of the benefits of {providerRewardsHeading} cards</Typography.H3>
      {generateBenefitsTable(directRewardPrograms[providerRewards])}

      {providerRewardsOffersList.length > 0 ? (
        <div>
          <Typography.H3>Current offers on {providerRewardsHeading} cards</Typography.H3>
          <Typography.P>
            {provider.heading} has the following {providerRewardsOffersList.length === 1 ? "offer" : "offers"} for people taking out a new {providerRewardsHeading} card.
          </Typography.P>
          <br />
          {providerRewardsOffersList}
        </div>
      ) : null}

      <Typography.H3>{questions[2]}</Typography.H3>
      <Typography.P>
        As the name suggests when you spend money on your {provider.heading} {rewards} card, you earn {rewards} Points directly. {provider.productsByRewards[rewards].some(product => product.tier1Cap === 9999999 || product.tier2Cap === 9999999) ? "Points are uncapped and the amount you’ll receive depends on how much you spend." : null}
      </Typography.P>
      <br />
      <Typography.P>{rewards} program is extensive, giving you the opportunity to use points for domestic and international flights and upgrades. You can also redeem points for:</Typography.P>
      <Typography.P>
        <ul>
          <li>hotels</li>
          <li>shopping, including gift cards and direct purchases from the Qantas Store</li>
          <li>purchases through Qantas Wine</li>
          <li>Qantas Travel Insurance, and</li>
          <li>hire cars and fuel through BP</li>
        </ul>
      </Typography.P>

      <Typography.H3>{questions[3]}</Typography.H3>
      {Object.keys(directRewardPrograms[rewards]).length === 1 ? (
        <Typography.P>
          The {directRewardPrograms[rewards][0].nameLong} credit card is the only {provider.heading} credit card that features {rewards}.
        </Typography.P>
      ) : (
        <Typography.P>
          {provider.heading} offers {Object.keys(directRewardPrograms[rewards]).length} different cards that link to {rewards}. These include the entry-level {directRewardPrograms[rewards][0].nameLong} card, as well as the {directRewardPrograms[rewards][1].nameLong}
          {directRewardPrograms[rewards][2] && " and the " + directRewardPrograms[rewards][2].nameLong}. For a higher fee, these offer the opportunity to earn more rewards points, and feature other travel-related benefits.
        </Typography.P>
      )}

      <Typography.H3>
        Summary of {provider.heading} {rewards} Cards
      </Typography.H3>
      {generateBenefitsTable(directRewardPrograms[rewards])}

      {rewardsOffersList.length > 0 ? (
        <div>
          <Typography.H3>
            Current offers on {provider.heading} {rewards} cards
          </Typography.H3>
          <Typography.P>
            {provider.heading} has the following {rewardsOffersList.length === 1 ? "offer" : "offers"} for people taking out a new {provider.heading} {rewards} card.
          </Typography.P>
          <br />
          {rewardsOffersList}
        </div>
      ) : null}

      <Typography.H3>{questions[4]}</Typography.H3>
      <Typography.P>
        Ultimately, whether you should choose a {providerRewardsHeading} or a {provider.heading} {rewards} credit card depends largely on how you intend to redeem the points.
      </Typography.P>
      <br />
      <Typography.P>
        If you’re happy flying {rewards.split(" ")[0]} and shopping through its partners, then earning {rewards} points directly can be a great option.
        {provider.heading !== "Woolworths" && indirectRewardPrograms.length > 1 ? (
          <span>
            {" "}
            Alternatively, if you’d prefer to fly another airline, then the {providerRewardsHeading} card gives you the opportunity to redeem through {indirectRewardPrograms.length} different frequent flyer loyalty programs schemes {rewards === "Qantas Frequent Flyer" ? " (Qantas isn’t one of them)." : ""}
          </span>
        ) : null}
      </Typography.P>
      <br />
      <Typography.P>
        If cost is a consideration, it’s also worth noting that the {providerRewardsHeading} cards tends to be slightly cheaper than the {provider.heading} {rewards} cards.
        {provider.heading !== "Woolworths" && indirectRewardPrograms.length > 0 ? <span> But if you’re earning more points on an airline you’d choose to fly, this probably won’t matter.</span> : null}
      </Typography.P>
      <br />
      <Typography.P>Alternatively, if you don’t feel like you’ll get value out of rewards points, a low-cost no-frills card could save you money.</Typography.P>

      <Typography.H3>In short…</Typography.H3>
      <Typography.P>Both cards offer great rewards programs and the choice ultimately comes down to personal preference.</Typography.P>
      <br />
      {/* <Typography.P>Discover how you can maximise the number of points you earn.</Typography.P> */}
    </div>
  )
}

export default ProviderArticle
